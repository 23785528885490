@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Merriweather+Sans'); // feel free to change this font type at any momment.

$primary: #fdc200; /* MAIN COLOR */
$secondary: #484d5b; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #000;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


body {
	font-size: 1.5em;

	h1,h2,h3,h4,h5,h6 {
		font-size:2em;
	}
}

nav {
	z-index: 10;
}
.navbar-brand {
    height: 120px; // this is what determine the height of the nav
    padding: 5px;
		@media(max-width: 767px){
			height: 70px; // nav height mobile,(feel free to change this values as you please)
			.logo {
				max-height: 60px; // logo height changed on mobile
			}
		}
}
.navbar .navbar-nav {
> li > a {
			font-family: 'Merriweather Sans', sans-serif;
			font-size: 18px;
			text-align: center;
			margin-top: 42px;
			display: flex;
			align-items: center;
			color: black;
			transition: 1s all ease;


			@media (max-width: 767px) {
				margin-top: 0;
			    display: inline-block;
			    height: 25px;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
}
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

// here start mixins ---- this is a style for buttons you can add it to your a tag like this (@include btn, look at the .btn-default style), these values can be changed anytime you need to.
@mixin btn{
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: 0px;
	background: $primary;
	color: $wht;
	padding: 0.5em 1em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: $wht;
		box-shadow: 0px 0px 3px lighten($blk,20%);
		text-decoration: none;

	}
}

.btn-default {
	@include btn;
}
// mixins end here


// sections start
.section-a {
	padding: 60px;
	margin-top: 100px; // this marging  will depend on the height of the nav, (if you have a bigger or smaller nav feel free to change it)  'if you need a transparent nav and see the img change margin to padding'

}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 90%;
	text-align: center;
	margin: 6em auto;

	.close {
		display: normal;
	}

	.modal-content {
		color: $primary;
		padding-bottom: 46px;

		h2 {
			font-family: 'Alfa Slab One', serif;
			font-size: 20px;
			line-height: 15px;
			text-align:center;
			
			@media (max-width: 767px) {
				font-size: 15px;
			}
		}

		p {
			font-size: 14px;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;
	
	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}
		
		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}		
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}	
}



.logo {
	max-width: 350px;
	margin-top: 10px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

$bgClr: rgba(#fdc200, .83);
$txtClr: #fff;
$animSpd: 750 * 1ms; // Change also in JS

$numOfSlides: 5; // Add new city in the array in JS and a new image below

$bgPics: (
url('../img/slide1.jpg') center center no-repeat,
url('../img/slide2.jpg') center center no-repeat,	
url('../img/slide3.jpg') center center no-repeat,
url('../img/slide4.jpg') center center no-repeat,
url('../img/slide5.jpg') center center no-repeat
);

@mixin mediaMaxW($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

.cont {
	position: relative;
	height: 100vh;
	overflow: hidden;
	user-select: none;
	cursor: default !important;

	@media (max-width: 360px) {
		background: url('../img/slide1.jpg');
		background-position: 20% 0%;
		background-size: cover;
	}

	@media (max-width: 660px) and (orientation: landscape) {
		background: url('../img/slide1.jpg');
		background-position: 20% 0%;
		background-size: cover !important;
	}
}

.slider {
	position: relative;
	height: 100%;
	cursor: normal;
	transform: translate3d(0, 0, 0);
	will-change: transform;
	user-select: none;

	&.animation {
		transition: transform $animSpd ease-in-out;

		.slide__darkbg {
			transition: transform $animSpd ease-in-out;
		}
		
		.slide__text {
			transition: transform $animSpd ease-in-out;
		}
		
		.slide__letter {
			transition: transform $animSpd ease-in-out;
		}
	}
}

.slide {
	position: absolute;
	top: 0;
	width: 100%;
	cursor: normal;
	height: 100%;
	overflow: hidden !important;
	
	@for $i from 1 through $numOfSlides {
		&--#{$i} {
			left: 100% * ($i - 1);
		}
	}
	
	&__darkbg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform: translate3d(0, 0, 0);
		will-change: transform;
		z-index: 10;
	}
	
	&__text-wrapper {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		z-index: 10;
		user-select: none;
	}
	
	&__letter {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translate3d(0, 0, 0);
		-webkit-text-fill-color: transparent !important;
		-webkit-background-clip: text !important;
		font: {
			size: 50vw;;
			weight: 800;
		}
		color: #000;
		z-index: 2;
		will-change: transform;
		user-select: none;
	}
	
	&__text {
		font: {
			size: 8vw;
			weight: 800;
		}
		text-transform: uppercase;
		transform: translate3d(0, 0, 0);
		letter-spacing: 10px;
		color: $txtClr;
		text-shadow: 2px 2px 14px red;
		will-change: transform;
		user-select: none;

		@media (max-width: 500px) {
			letter-spacing: 2px;
			size: 8vw;
		}

	}
	&__text:nth-child(odd) {
		z-index: 2;
	}
	&__text:nth-child(even) {
		z-index: 2;
	}
		
	@each $item in $bgPics {
		$i: index($bgPics, $item);
		&--#{$i} {
			&__darkbg {
				left: -50% * ($i - 1);
				background: $item;
				background-size: cover;
				background-position: 0px center, 0px center;
				transform: translate3d(0, 0, 0);
				will-change: transform;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: $bgClr;
				}
			}
			&__letter {
				background: $item;
				background-position: 0px center, 0px center;
				background-size: cover;
			}
		}
	}
}

.navslide {
	cursor: none;
	position: absolute;
	bottom: 25px;
	left: 51%;
	transform: translateX(-50%);
	list-style-type: none;
	z-index: 10;

	@media (max-width: 990px) {
		left: 55%;
	}

	@media (max-width: 767px) {
		left: 55%;
	}
	
	&__slide {
		position: relative;
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 2px solid #fff;
		margin-left: 3px;
		cursor: pointer;
		@include mediaMaxW(400px) {
			width: 22px;
			height: 22px;
		}
		&:hover:after {
			transform: translate(-50%, -50%) scale(1,1);
			opacity: 1;
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0,0);
			width: 75%;
			height: 75%;
			border-radius: 50%;
			background-color: #fff;
			opacity: 0;
			transition: 300ms;
		}
		
		&--1 {
			margin-left: 0;
		}
	}
}

.navslide-active {
	&:after {
		transform: translate(-50%, -50%) scale(1,1);
		opacity: 1;
	}
}

.side-nav {
	position: absolute;
	width: 10%;
	height: 100%;
	top: 0;
	z-index: 20;
	cursor: pointer;
	opacity: 0;
	transition: 300ms;
	
	&:hover {
		opacity: .1;
	}
	
	&--right {
		right: 0;
		background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(238, 215, 255) 100%);
	}
	&--left {
		left: 0;
		background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgb(238, 215, 255) 100%);
	}
}


.color {
	padding: 40px 0;
	background: $primary;
}

.color2 {
	padding: 50px 0;
	background: darken(orange, 3%);
}

.title {
	font-family: 'Alfa Slab One', sans-serif;
	color: $wht;
	margin-top: 70px;
	
	@media (max-width: 990px) {
		font-size: 25px;
		text-align: center;
	}
}

.title2 {
	font-family: 'Alfa Slab One', sans-serif;
	color: $wht;
	font-size: 72px;

	@media (max-width: 990px) {
		font-size: 50px;
	}
}

.text {
	font-family: 'Merriweather Sans', sans-serif;
	font-size: 16px;
	color: $blk;
}

.aboutbg {
	background: url('../img/aboutbg.jpg');
	background-size: cover;
	padding: 280px 0;

	@media (max-width: 500px) {
		padding: 100px 0;
	}
}

.aboutTitle {
	font-family: 'Alfa Slab One', sans-serif;
	color: $wht;
	font-size: 72px;
	text-align: right;

	@media (max-width: 990px) {
		font-size: 28px;
	}
}

.aboutText {
	font-family: 'Merriweather Sans', sans-serif;
	text-align: center;
	font-size: 18px;
	color: $wht;
}

.contact {
	background-color: darken(orange, 10%);
	padding: 45px 0;
	color: $wht;
	font-family: 'Merriweather Sans', serif;
	h3 {
		text-align: center;
		color: $wht;
		font-family: 'Alfa Slab One', serif;
		font-size: 30px;
	}
}

.info {
	color: $wht;
	font-family: 'Merriweather Sans', sans-serif;
	font-size: 16px;
	line-height: 10px;

	@media (max-width: 767px) {
		line-height: 17px;
	}
}


ul {
	margin-left: -40px;
}

.no_bullet {
	list-style: none;
}